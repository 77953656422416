import { Component,HostListener  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TMD';
  constructor(private translate: TranslateService,private authService: AuthService) {
    const browserLang = translate.getBrowserLang();
   // translate.use(browserLang.match(/fr|ar/) ? browserLang : 'en');
    translate.setDefaultLang('fr');  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.setDirection(language);
  }
  setDirection(language: string) {
    const dir = language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
  }
   // HostListener listens for keydown events
 /*  @HostListener('window:keydown', ['$event'])
   handleKeyDown(event: KeyboardEvent) {
     // Prevent refresh on F5 or Ctrl+R
     if (event.key === 'F5' || (event.ctrlKey && event.key === 'r')) {
       event.preventDefault();  // Stops the refresh action
       console.log('Page refresh disabled');
     }
   }
     // Listen for page unload or refresh
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: Event) {
    // Optional: Show confirmation dialog when user tries to refresh or close the page
    event.returnValue = false; // Prevent page unload
  }
  */
  ngOnInit(): void {
    // Retrieve the token from storage when the app starts
    const token = this.authService.getToken();
    if (token) {
      console.log('Token exists:', token);
      this.authService.Fetchdata(token);
      // You can use this token for further authentication steps
    } else {
      console.log('No token found, user needs to log in.');
    }
  }
}


