import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,RouterStateSnapshot,Router } from '@angular/router';
// import { BehaviorSubject } from 'rxjs';
import { routes } from '../routes/routes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map} from 'rxjs/operators';
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
interface TokenResponse {  access: string;}
interface UserDetails {
  id: number;
  user: User;
  nom: string;
  prenom: string;
  cin_number: string;
  date_of_issue: string;
  date_of_birth: string;
  place_of_birth: string;
  driving_license_number: string;
  education_level: string;
  phone_number: string;
  email: string;
  user_type: number;
}
interface User {
  last_login: string;
  email: string;
  is_active: boolean;
}
interface Token_decode {
  token_type: string;
  email: string;
  jti: string;
  user_id: string;
  user_type: number;
}
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
@Injectable({  providedIn: 'root',})
export class AuthService {
  private apiUrl = environment.apiUrl; // Utilisation de l’URL API
  private authUrl = this.apiUrl+'/api/accounts/api/token/'; // authentication API URL
  private userDetailsUrl = this.apiUrl+'/api/accounts/api/user-details/'; //user details API URL
  public register_api = this.apiUrl+'/api/accounts/combined/';
  public apiAddPM = this.apiUrl+'/api/accounts/personne_morale/create/'; // add PM API URL
  public apiAddMedecin = this.apiUrl+'/medecin/create-medecin/'; // add medecin API URL
  public apiAddcentre = this.apiUrl+'/formation/centers/'; // add centre API URL
  private ApiReset = this.apiUrl+'/api/accounts/password_reset/';
  //private apiAddcentre = '';
  userDetails: any | null = null;
  public Token!: string ;
  private tokenKey = 'authToken';
  
  error: string | null = null;
  constructor(private router: Router,private http: HttpClient) {}
  private userDetailsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  setToken(token: string): void {    localStorage.setItem(this.tokenKey, token);  }
    // Retrieve token from local storage
    getToken(): string | null {      return localStorage.getItem(this.tokenKey);  }
      // Remove token
  clearToken(): void {    localStorage.removeItem(this.tokenKey);     window.sessionStorage.clear(); }
   saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }
  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }

  login(email: string, password: string): Observable<TokenResponse> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' ,   "Accept": "*/*"});
    const body = { email, password };
    localStorage.setItem('authenticated', 'true');
    console.log(body.email)
    return this.http.post<TokenResponse>(this.authUrl, body, { headers });
  }
  getUserDetails(token: string): Observable<UserDetails> {
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}` });
    return this.http.get<UserDetails>(this.userDetailsUrl, { headers });
  }

  Fetchdata(token: string) {
     this.Token= token
    this.getUserDetails(token).subscribe({next: (details) => {
       this.userDetails = details;
      this.userDetails =this.userDetails.user_details;
      this.userDetailsSubject.next(this.userDetails);
      const userType =this.userDetails.user_type[0];
      const OrgType =this.userDetails.type_org;
       console.log(this.userDetails);
       console.log('User Type:', userType);
       this.error = null;
       //this.isLoggedIn = true;
       if(userType===4){       this.router.navigate([routes.adminDashboard]);
     }if(userType===3 && OrgType == 2){       this.router.navigate([routes.doctorDashboard]);      
      }if(userType===2){       this.router.navigate([routes.patientDashboard]); }
       if(userType===3 && OrgType == 1){        this.router.navigate([routes.centerDashboard]); }
      },
      error: (err) => {
        this.userDetails = null;
        this.error = 'Failed to retrieve user details.';
        console.error('User details error:', err);      }
    });
  }
  ResetForm(data: any) { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' ,   "Accept": "*/*"});
    return this.http.post(this.ApiReset, data ,{ headers })
    .pipe(catchError(error => {
        // Proper error handling
        if (error.status !== undefined) {
          console.error('HTTP Error Status:', error.status);
        } else {
          console.error('Error does not have status. Full error:', error);
        }
        return throwError(error); // Rethrow the error for further handling
      }) )  ;  }
  RegisterForm(data: any) {    return this.http.post(this.register_api, data);  }
  addPmForm(data: any) {    return this.http.post(this.apiAddPM, data);  }
  addMedForm(data: any) {    return this.http.post(this.apiAddMedecin, data);  }
  addCenterForm(data: any) {    return this.http.post(this.apiAddcentre, data);  }

    // Method to retrieve user details
    getDetails(): Observable<any> {
     // console.log(this.userDetailsSubject);
      return this.userDetailsSubject.asObservable();
    }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot):  boolean {
      if (localStorage.getItem(this.tokenKey) != null)
      console.log(localStorage.getItem(this.tokenKey));
      return true;
      this.router.navigate(['/register']);
      return false;
  }
  logout() {
    this.clearToken(); // Other logout actions such as navigating to the login page
    console.log("token deleted")
  }
  /*
  logout(): Observable<any> {
     window.sessionStorage.clear();
        //remove user from localStorage
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userToken');     
        this.router.navigateByUrl("/login");
        localStorage.setItem('authenticated', 'false');
    return this.http.post(AUTH_API + 'signout', { }, httpOptions);
  }*/
}

/*
function flattenUserDetails(details: any): any {
  const flattened: any = { ...details };
  // Flatten the nested user object
  Object.entries(details.user).forEach(([key, value]) => {
    flattened[`user_${key}`] = value;  });
  // Remove the original user object
  delete flattened.user;
  return flattened;
}*/