    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm header-inner" id="mainNav" style="
    background-color: white;opacity: 0.8;">
      <div class="container px-5">
          <a class="navbar-brand" href="#">
              <img src="assets\img\logo.png" alt="" width="70" height="70">
            </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i class="bi-list"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
              <ul class="navbar-nav ms-auto my-lg-0">
                  <li class="nav-item"><a class="nav-link me-lg-3" href="login"> {{ 'login.connect' | translate }} </a></li>
                  <li class="nav-item"><a class="nav-link me-lg-3" href="register">{{ 'login.signup' | translate }}</a></li>
                  <li class="nav-item"><a class="nav-link me-lg-3" href="register_organism"> {{ 'login.dashboard' | translate }} </a></li>
                  <li class="nav-item"><a class="nav-link me-lg-3" href="dashboard">{{ 'login.utica' | translate }} </a></li>
              </ul>
          </div>
          <div class="col-md-6 text-end">  
              <button class="btn btn-primary me-2" (click)="switchLanguage('fr')"> {{ 'login.button.fr' | translate }} </button>
              <button class="btn btn-primary" (click)="switchLanguage('ar')">{{ 'login.button.ar' | translate }}</button>	 
      </div>
      </div>
  </nav>
<header class="masthead">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                    <!-- Page heading-->
                    <p class="slogan">
                        <span class="white">{{ 'login.slogan' | translate }}</span> &nbsp;
                        <span class="red">{{ 'login.organism' | translate }}</span> &nbsp;
                   <!--     <span class="red"> TMD </span> &nbsp; -->
                      </p>
                    <!-- to get an API token!-->
            </div>
        </div>
    </div><br>
    <div class="container responsive-grid">
</div>
</header>