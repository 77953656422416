import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  private authUrl = 'https://tmd.com.tn/api/accounts/api/token/'; // authentication API URL
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the token from local storage or any other secure storage
    const token = localStorage.getItem('userToken');

    // Clone the request to add the new header
    let authReq = req;
    if (token) {
      authReq = req.clone({withCredentials: true,
        setHeaders: { Authorization: `Bearer ${token}`, },  });
    }
    //console.log(token + "heeee")
    // Send the newly created request
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle 401 Unauthorized errors
        if (error.status === 401) {
          if (!authReq.url.includes('/home')) {
          // Redirect to the login page or handle token expiration
          this.router.navigate(['/login']);
        }
      }
        // Handle other types of errors
        return throwError(() => new Error(error.message));
      })
    );
  }
}